<template>
  <div>
    <v-select
      v-model="model"
      dense
      hide-details
      :items="allItems"
      outlined
      :chips="items[0] && items[0].color !== undefined"
      :label="`Filter by ${label}`"
      class="input-max-width"
      color="primary"
    >
      <template v-slot:selection="{ item }">
        <span v-if="!item.color">{{ item.text }}</span>
        <v-chip
          v-else
          :color="item.color"
          small
          light
        >
          {{ item.text }}
        </v-chip>
      </template>
      <template v-slot:item="{ item }">
        <span v-if="!item.color">{{ item.text }}</span>
        <v-chip
          v-else
          :color="item.color"
          light
        >
          {{ item.text }}
        </v-chip>
      </template>
    </v-select>
  </div>
</template>

<script>

export default {

  props: {
    items: {
      type: Array,
      default: () => ([]),
      required: true
    },
    propName: {
      type: String,
      default: null,
      required: true
    },
    storeAction: {
      type: String,
      default: null,
      required: true
    },
    label: {
      type: String,
      default: 'Status'
    }
  },

  data: function () {
    return {
      all: [
        { text: 'All', value: null }
      ]
    }
  },

  computed: {
    model: {
      get () {
        return this.$store.getters[this.propName]()
      },
      set (value) {
        this.$store.dispatch(this.storeAction, value)
      }
    },
    allItems () {
      return [...this.all, ...this.items]
    }
    // pascalPropName () {
    //   return this.propName.charAt(0).toUpperCase() + this.propName.slice(1)
    // }
  }
}
</script>
