var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-select", {
        staticClass: "input-max-width",
        attrs: {
          dense: "",
          "hide-details": "",
          items: _vm.allItems,
          outlined: "",
          chips: _vm.items[0] && _vm.items[0].color !== undefined,
          label: "Filter by " + _vm.label,
          color: "primary"
        },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function(ref) {
              var item = ref.item
              return [
                !item.color
                  ? _c("span", [_vm._v(_vm._s(item.text))])
                  : _c(
                      "v-chip",
                      { attrs: { color: item.color, small: "", light: "" } },
                      [_vm._v("\n        " + _vm._s(item.text) + "\n      ")]
                    )
              ]
            }
          },
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                !item.color
                  ? _c("span", [_vm._v(_vm._s(item.text))])
                  : _c("v-chip", { attrs: { color: item.color, light: "" } }, [
                      _vm._v("\n        " + _vm._s(item.text) + "\n      ")
                    ])
              ]
            }
          }
        ]),
        model: {
          value: _vm.model,
          callback: function($$v) {
            _vm.model = $$v
          },
          expression: "model"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }